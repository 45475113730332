
   .AppBar {
    text-align: center;
    /* background:  linear-gradient(to bottom right, #6c5ce7, #00b894); */
    background: radial-gradient(circle, #6c5ce7 0%, #00b894 100%);
    height: 15vh;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 0 2rem;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 5% 5%;
  }
  
  .AppBarTitle {
    color: white;
    text-align: start;
    align-items: center;
    font-size: 30px;
    height: 4vh;
    font-weight: bolder;
    padding-top: 1vh;
    padding-left: 15px;
  }
  
  .AppBarSet {
    color: white;
    gap: 10px;
    align-items: center;
    width: 12vw;
    text-align: end;
    align-items: center;
    font-size: 20px;
    height: 4vh;
    padding-top: 7vh;
    padding-left: 15px;
    text-decoration: none;
  }
  
  .Content {
    height: 85vh;
    padding-left: 20vw;
    padding-right: 20vw;
    background-color: #eeeeee;
  }
  .AppBarSet:hover {
    color: #ffc107;
  }
  
  @media (max-width: 768px) {
    .AppBar {
      height: 10vh;
      padding: 0 1rem;
    }
    
    .AppBarTitle {
      font-size: 24px;
    }
    
    .AppBarSet {
      font-size: 16px;
      width: 20vw;
      padding-top: 5vh;
    }
    
    .Content {
      padding: 1rem;
    }
  }
  
.Padding {
    padding: 10px;
}

.DateIcBtn {
    width: 30px;
    height: 30px;
    color: gray;
}

.TextTitle {
    font-weight: bold;
    font-size: 30px;
    color: dimgray;
}

.TextDesc {
    font-size: 25px;
    color: dimgray;
}

.TextNum {
    font-weight: bold;
    font-size: 20px;
    color: black;
}

.CardItem {
    flex: 1;
    align-items: center;
}

.MarginButton {
    margin-bottom: 10px;
}

.MarginSide {
    margin-left: 5vh;
    margin-right: 5vh;
}

.btnText {
    margin-right: 15px;
    font-size: 5px;
    justify-content: center;
    align-items: center;
}

.Padding {
    padding: 10px;
}

.BtnText {
    margin-right: 15px;
    font-size: 5px;
    justify-content: center;
    align-items: center;
}

.Icon {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.UserIc {
    width: 40px;
    height: 40px;
}
/* GroupedBtns VS and SS, AS and RE */
.GroupedBtns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid #CCCCCC;
    border-radius: 20px;
    padding: 5px;
}

.GroupedBtns > span {
    margin: 0 5px;
}


  
 
  

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
