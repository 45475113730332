.base-container{
    width:100%;
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header{
        font-size: 24px;
        font-family: "Open Sans",sans-serif;
        font-weight: bold;
        padding-bottom: 20px;
    }
    .content{
        display: flex;
        flex-direction:column;
        .image{
            width:21em;
                img{
                    width: 100%;
                    height:100%;

                }
        }
        .form{
            margin-top:2em;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-height: 300px; /* add max-height property */
            overflow-y: scroll; /* add overflow-y property */
            &::-webkit-scrollbar {
                width: 10px;
              }
            
              &::-webkit-scrollbar-track {
                background-color: #f5f5f5;
              }
            
              &::-webkit-scrollbar-thumb {
                background-color: #c8c8c8;
                border-radius: 5px;
              }
            
              &::-webkit-scrollbar-thumb:hover {
                background-color: #a0a0a0;
              }
            .form-group{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: fit-content;
                label{
                    font-size: 20px;

                }
                input{
                    margin-top:6px; ;
                    min-width: 18em;
                    height:37px;
                    padding:0px 10px;
                    font-family: 'Open Sans',sans-serif;
                    background-color: #f3f3f3;
                    border:0;
                    border-radius: 4px;
                    margin-bottom: 31px;
                    transition: all 250ms ease-in-out;
                    &:focus{
                        outline:none;
                        box-shadow: 0px 0px 12px 0.8px #8884d8;
                    }
                }
            }
        }
        .footer{
            margin-top: 3em;
        }
    }
   
}